import axios from 'axios';
import { GraphQLClient } from 'graphql-request';
import { graphql } from '../gql';
import {
  AggregateMaterialQuery,
  AllCategoriesByPageQuery,
  BulkImportStatus,
  CategoriesQuery,
  CategoriesWithMaterialsQuery,
  CreateOneCategoryMutation,
  CreateOneCategoryMutationVariables,
  CreateOneMaterialMutation,
  CreateOneMaterialMutationVariables,
  CreateOnePriceGroupMutation,
  DeleteOneMaterialMutation,
  MaterialsWithCategoriesQuery,
  ProductOptionsImportStatus,
  QueryMode,
  SortOrder,
  UpdateMaterialWithShopifyDefinitionMutation,
  UpdateMaterialWithShopifyDefinitionMutationVariables,
  UpdateOnePriceGroupMutation,
} from '../gql/graphql';
import { itemsPerPage } from '../constants/stringConstants';
import { UPDATE_ONE_PRICE_GROUP_MUTATION } from './gql.services';

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
const authToken = process.env.REACT_APP_API_AUTH_TOKEN;
const authTokenForFileUpload = process.env.REACT_APP_API_AUTH_TOKEN_FILE_UPLOAD;

const gqlClient = new GraphQLClient(`${apiEndPoint!}/graphql`, {
  headers:
    process.env.REACT_APP_ENV === 'local'
      ? {
          Authorization: `Bearer ${authToken}`,
        }
      : undefined,
});
const axiosInstanceForFileUpload = axios.create({
  headers: {
    Authorization:
      process.env.REACT_APP_ENV === 'local'
        ? `Bearer ${authTokenForFileUpload}`
        : undefined,
    'Content-Type': 'multipart/form-data',
    Accept: '*/*',
  },
});
export const updateAxiosAuthToken = (token: string) => {
  if (window.localStorage) {
    window.localStorage.setItem('jwt', token);
  }

  gqlClient.setHeader('Authorization', `Bearer ${token}`);
  axiosInstanceForFileUpload.interceptors.request.use(config => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export type MaterialProps = {
  title: string;
  id: string;
  Category: { title: string; id: string };
};
/** *  Fetch all products from frontend ** */

/** ** API Calls for OPTIONS SETS *** */

export const fetchShopifyProducts = async (params: any) => {
  const queryDoc = graphql(/* */ `
    query ShopifyProducts(
      $first: Int
      $reverse: Boolean
      $last: Int
      $before: String
      $after: String
      $query: String
    ) {
      shopifyProducts(
        first: $first
        reverse: $reverse
        last: $last
        before: $before
        after: $after
        query: $query
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        queryResult
      }
    }
  `);
  const variables: any = {
    reverse: true,
  };
  if (params.after.length !== 0) {
    variables.first = 25;
    variables.after = params.after;
  } else if (params.before.length !== 0) {
    variables.last = 25;
    variables.before = params.before;
  }

  if (params.after.trim().length === 0 && params.before.trim().length === 0)
    variables.first = 25;
  if (params.searchQuery.trim().length !== 0) {
    variables.query = `title:*${params.searchQuery}*`;
  }
  return gqlClient.request(queryDoc, variables);
};

// api call to get all the materials
export const getAllMaterialsByPage: (params: {
  page: number;
  searchTerm?: string;
}) => Promise<MaterialsWithCategoriesQuery> = async params => {
  const queryDoc = graphql(/* GraphQL */ `
    query MaterialsWithCategories(
      $take: Int
      $skip: Int
      $where: MaterialWhereInput
      $orderBy: [MaterialOrderByWithRelationInput!]
    ) {
      materials(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
        title
        previewImageUrl
        previewImageId
        metaObjectId
        id
        Category {
          title
          id
        }
      }
    }
  `);
  return gqlClient.request(queryDoc, {
    take: itemsPerPage,
    skip: params.page === 0 ? 0 : (params.page - 1) * itemsPerPage,
    orderBy: [
      {
        createdAt: SortOrder.Desc,
      },
    ],
    where: {
      title: {
        contains: params.searchTerm,
        mode: QueryMode.Insensitive,
      },
    },
  });
};

// api call to get all the category
export const getAllCategories = async (params: {
  searchTerm?: string;
}): Promise<CategoriesQuery> => {
  const queryDoc = graphql(/* GraphQL */ `
    query Categories($where: CategoryWhereInput) {
      categories(where: $where) {
        title
        id
      }
    }
  `);
  const variables = {
    where: {
      title: {
        contains: params.searchTerm,
      },
    },
  };
  return gqlClient.request(queryDoc, variables);
};

// api call to get all the category with materials
export const getAllCategoriesWithMaterials =
  async (): Promise<CategoriesWithMaterialsQuery> => {
    const queryDoc = graphql(/* GraphQL */ `
      query CategoriesWithMaterials {
        categories {
          materials {
            id
            title
            previewImageUrl
          }
          title
          url
          id
          designer
          description
          composition
        }
      }
    `);

    return gqlClient.request(queryDoc);
  };

export const getAllCategoriesByPage = async (params: {
  searchTerm?: string;
  page: number;
}): Promise<AllCategoriesByPageQuery> => {
  const queryDoc = graphql(/* GraphQL */ `
    query AllCategoriesByPage(
      $where: CategoryWhereInput
      $take: Int
      $skip: Int
      $orderBy: [CategoryOrderByWithRelationInput!]
    ) {
      categories(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
        composition
        description
        designer
        id
        materials {
          id
          previewImageUrl
          title
        }
        title
        url
      }
    }
  `);
  const variables = {
    where: {
      title: {
        contains: params.searchTerm,
        mode: QueryMode.Insensitive,
      },
    },
    orderBy: [
      {
        createdAt: SortOrder.Desc,
      },
    ],
    take: itemsPerPage,
    skip: params.page === 0 ? 0 : (params.page - 1) * itemsPerPage,
  };

  return gqlClient.request(queryDoc, variables);
};

// api call to get all the material count
export const getTotalMaterialCounts =
  async (): Promise<AggregateMaterialQuery> => {
    const queryDoc = graphql(/* GraphQL */ `
      query AggregateMaterial {
        aggregateMaterial {
          _count {
            _all
          }
        }
      }
    `);

    return gqlClient.request(queryDoc);
  };

// api call to upload file to shopify
export const uploadFile = async (fileData: any) => {
  const res = await axiosInstanceForFileUpload.post(
    `${apiEndPoint as string}/api/materials/upload-file`,
    { image: fileData },
  );
  return res.data;
};

export const uploadBulkMaterialFile = async (fileData: any) => {
  const res = await axiosInstanceForFileUpload.post(
    `${apiEndPoint as string}/api/bulkImports`,
    { excelFile: fileData },
  );
  return res.data;
};

export const uploadBulkProductOptionFile = async (fileData: any) => {
  const res = await axiosInstanceForFileUpload.post(
    `${apiEndPoint as string}/api/productOptionImports`,
    { excelFile: fileData },
  );
  return res.data;
};
// api call to create new material
export const createNewMaterial = async (materialData: {
  title: string;
  uploadedFileId: string;
  categoryId?: string;
}): Promise<CreateOneMaterialMutation> => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation CreateOneMaterial($data: MaterialCreateWithShopifyInput!) {
      createOneMaterial(data: $data) {
        id
        previewImageUrl
        categoryId
        title
      }
    }
  `);
  let variables: CreateOneMaterialMutationVariables = {
    data: {
      title: `${materialData.title}`,
      uploadedFileId: `${materialData.uploadedFileId}`,
    },
  };
  if (materialData.categoryId) {
    variables = {
      ...variables,
      data: { ...variables.data, categoryId: `${materialData.categoryId}` },
    };
  }

  return gqlClient.request(queryDoc, variables);
};

// api call to update material data
export const updateMaterial = async (materialData: {
  title: string;
  categoryId?: string;
  uploadedFileId: string;
  id: string;
}): Promise<UpdateMaterialWithShopifyDefinitionMutation> => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation UpdateMaterialWithShopifyDefinition(
      $data: MaterialUpdateWithShopifyDefinitionInput!
      $where: MaterialWhereUniqueInput!
    ) {
      updateOneMaterial(data: $data, where: $where) {
        id
        previewImageUrl
        title
        categoryId
      }
    }
  `);
  let updateData: any = {
    title: {
      set: materialData.title,
    },
  };
  if (materialData.categoryId) {
    updateData = { ...updateData, categoryId: materialData.categoryId };
  }
  if (materialData.uploadedFileId !== '') {
    updateData = { ...updateData, uploadedFileId: materialData.uploadedFileId };
  }
  const variables: UpdateMaterialWithShopifyDefinitionMutationVariables = {
    where: {
      id: materialData.id,
    },
    data: updateData,
  };

  return gqlClient.request(queryDoc, variables);
};

export const updateManyMaterials = async (
  materialsData: { title: string; categoryId?: string; id: string }[],
) => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation UpdateMaterialWithShopifyDefinition(
      $data: MaterialUpdateWithShopifyDefinitionInput!
      $where: MaterialWhereUniqueInput!
    ) {
      updateOneMaterial(data: $data, where: $where) {
        id
        previewImageUrl
        title
        categoryId
      }
    }
  `);

  await Promise.all(
    materialsData.map(material => {
      let updateData: UpdateMaterialWithShopifyDefinitionMutationVariables['data'] =
        {
          title: {
            set: material.title,
          },
        };
      if (material.categoryId) {
        updateData = { ...updateData, categoryId: material.categoryId };
      }
      return gqlClient.request(queryDoc, {
        where: {
          id: material.id,
        },
        data: updateData,
      });
    }),
  );
};
// api call to delete material
export const deleteMaterialById = async (
  id: string,
): Promise<DeleteOneMaterialMutation> => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation DeleteOneMaterial($where: MaterialWhereUniqueInput!) {
      deleteOneMaterial(where: $where) {
        id
      }
    }
  `);
  const variables = {
    where: {
      id,
    },
  };

  return gqlClient.request(queryDoc, variables);
};

export type CreateOneCategoryVariables = Partial<
  Pick<
    CreateOneCategoryMutationVariables['data'],
    'url' | 'title' | 'designer' | 'description' | 'composition'
  >
> & { selectedMaterials: string[] };
// api call to create one category
export const createOneCategory = async (
  data: CreateOneCategoryVariables,
): Promise<CreateOneCategoryMutation> => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation CreateOneCategory($data: CategoryCreateInput!) {
      createOneCategory(data: $data) {
        id
        title
        url
      }
    }
  `);
  const variables: CreateOneCategoryMutationVariables = {
    data: {
      title: data.title ?? '',
      designer: data.designer ?? '',
      description: data.description ?? '',
      composition: data.composition ?? '',
      url: data.url ?? '',
    },
  };
  const selectedMaterials = data.selectedMaterials.map(
    (materialId: string) => ({ id: materialId }),
  );
  if (selectedMaterials) {
    variables.data.materials = {
      connect: selectedMaterials,
    };
  }

  return gqlClient.request(queryDoc, variables);
};

export const updateCategory = async (categoryData: any) => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation UpdateOneCategory(
      $data: CategoryUpdateInput!
      $where: CategoryWhereUniqueInput!
    ) {
      updateOneCategory(data: $data, where: $where) {
        title
        url
        composition
        designer
        materials {
          id
          previewImageUrl
          title
        }
      }
    }
  `);

  const variables: any = {
    data: {},
    where: {
      id: categoryData?.id,
    },
  };
  if (categoryData.title) {
    variables.data.title = { set: categoryData.title };
  }
  if (categoryData.composition) {
    variables.data.composition = { set: categoryData.composition };
  }
  if (categoryData.description) {
    variables.data.description = { set: categoryData.description };
  }
  if (categoryData.designer) {
    variables.data.designer = { set: categoryData.designer };
  }
  if (categoryData.url) {
    variables.data.url = { set: categoryData.url };
  }
  const selectedMaterials = categoryData.selectedMaterials.map(
    (materialId: string) => ({ id: materialId }),
  );
  if (selectedMaterials) {
    variables.data.materials = {
      set: selectedMaterials,
    };
  }

  return gqlClient.request(queryDoc, variables);
};

export const deleteCategoryById = async (categoryId: string) => {
  const categoryConnectedResponse = await gqlClient.request(
    graphql(/* GraphQL */ `
      query Category($where: CategoryWhereUniqueInput!) {
        category(where: $where) {
          _count {
            PriceSubGroup
            materials
          }
        }
      }
    `),
    {
      where: {
        id: categoryId,
      },
    },
  );
  if (
    categoryConnectedResponse?.category?._count?.PriceSubGroup! > 0 ||
    categoryConnectedResponse.category?._count?.materials! > 0
  ) {
    const error = new Error(
      'Material can not be deleted as it is connected to price subgroups or colors.',
    );
    error.name = 'Local';
    throw error;
  }
  const queryDoc = graphql(/* GraphQL */ `
    mutation DeleteOneCategory($where: CategoryWhereUniqueInput!) {
      deleteOneCategory(where: $where) {
        id
        title
      }
    }
  `);
  const variables = {
    where: {
      id: categoryId,
    },
  };

  return gqlClient.request(queryDoc, variables);
};
export const deletePriceSubgroupById = async (priceSubgroupId: string) => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation DeleteOnePriceSubGroup($where: PriceSubGroupWhereUniqueInput!) {
      deleteOnePriceSubGroup(where: $where) {
        id
        title
      }
    }
  `);
  const variables = {
    where: {
      id: priceSubgroupId,
    },
  };

  return gqlClient.request(queryDoc, variables);
};
export const getAllCategoriesCount = async (searchQuery: string) => {
  const queryDoc = graphql(/* GraphQL */ `
    query AggregateCategory($where: CategoryWhereInput) {
      aggregateCategory(where: $where) {
        _count {
          _all
        }
      }
    }
  `);
  const variables = {
    where: {
      title: {
        contains: searchQuery,
      },
    },
  };

  return gqlClient.request(queryDoc, variables);
};

export const getSuccessfullyImportedMaterialRowsCount = async () => {
  const queryDoc = graphql(/* GraphQL */ `
    query _count($where: MaterialImportSheetRowWhereInput) {
      aggregateMaterialImportSheetRow(where: $where) {
        _count {
          _all
        }
      }
    }
  `);
  const variables = {
    where: {
      status: {
        equals: BulkImportStatus.Success,
      },
    },
  };
  return gqlClient.request(queryDoc, variables);
};
export const getSuccessfullyImportedProductSheetsCount = async () => {
  const queryDoc = graphql(/* */ `
    query AggregateProductOptionsImportSheetSuccessfully(
      $where: ProductOptionsImportSheetWhereInput
    ) {
      aggregateProductOptionsImportSheet(where: $where) {
        _count {
          _all
        }
      }
    }
  `);
  const variables = {
    where: {
      status: {
        equals: ProductOptionsImportStatus.Success,
      },
    },
  };
  return gqlClient.request(queryDoc, variables);
};
export const getAllImportedMaterialRowsCount = async () => {
  const queryDoc = graphql(/* GraphQL */ `
    query AggregateMaterialImportSheetRow {
      aggregateMaterialImportSheetRow {
        _count {
          _all
        }
      }
    }
  `);
  return gqlClient.request(queryDoc);
};

export const getAllProductImportSheetsCount = async () => {
  const queryDoc = graphql(/* */
  `
    query AggregateProductOptionsImportSheet {
      aggregateProductOptionsImportSheet {
        _count {
          _all
        }
      }
    }
  `);
  return gqlClient.request(queryDoc);
};
export const getMaterialSheetRowsByPage = async (params: { page: number }) => {
  const queryDoc = graphql(/* GraphQL */ `
    query MaterialImportSheetRows(
      $skip: Int
      $take: Int
      $orderBy: [MaterialImportSheetRowOrderByWithRelationInput!]
    ) {
      materialImportSheetRows(skip: $skip, take: $take, orderBy: $orderBy) {
        id
        message
        status
        data
        associatedMaterial {
          previewImageUrl
          previewImageId
          title
        }
      }
    }
  `);
  const variables = {
    take: itemsPerPage,
    skip: params.page === 0 ? 0 : (params.page - 1) * itemsPerPage,
    orderBy: [
      {
        status: SortOrder.Desc,
      },
    ],
  };
  return gqlClient.request(queryDoc, variables);
};

export const getProductOptionsSheetsByPage = async (params: {
  page: number;
}) => {
  const queryDoc = graphql(/* GraphQL */ `
    query ProductOptionsImportSheets(
      $orderBy: [ProductOptionsImportSheetOrderByWithRelationInput!]
      $take: Int
      $skip: Int
    ) {
      productOptionsImportSheets(orderBy: $orderBy, take: $take, skip: $skip) {
        id
        status
        resultFile
      }
    }
  `);
  const variables = {
    take: itemsPerPage,
    skip: params.page === 0 ? 0 : (params.page - 1) * itemsPerPage,
    orderBy: [
      {
        createdAt: SortOrder.Desc,
      },
    ],
  };
  return gqlClient.request(queryDoc, variables);
};
export const getAllMaterialsWithoutCategory = async (params: any) => {
  const queryDoc = graphql(/* GraphQL */ `
    query Materials($where: MaterialWhereInput) {
      materials(where: $where) {
        id
        title
        previewImageUrl
      }
    }
  `);
  const variables = {
    where: {
      Category: null,
      title: {
        contains: params.searchQuery,
      },
    },
  };

  return gqlClient.request(queryDoc, variables);
};
export const deleteOneMaterialRow = async (id: string) => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation DeleteOneMaterialImportSheetRow(
      $where: MaterialImportSheetRowWhereUniqueInput!
    ) {
      deleteOneMaterialImportSheetRow(where: $where) {
        id
        data
      }
    }
  `);
  const variables = {
    where: {
      id,
    },
  };
  return gqlClient.request(queryDoc, variables);
};
export const getAllMaterials = async () => {
  const queryDoc = graphql(/* GraphQL */ `
    query MaterialsForPriceSubGroupMutation {
      materials {
        id
        previewImageUrl
      }
    }
  `);
  return gqlClient.request(queryDoc);
};
// api call to update product variant prices
export const updateProductVariantPrices = async (
  variantsData: {
    variantGqlId: string;
    compareAtPrice: string | null;
    price: string;
  }[],
) => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation UpdateProductVariantPrice(
      $data: [UpdateProductVariantPriceInput!]!
    ) {
      updateProductVariantPrice(data: $data) {
        compareAtPrice
        id
        price
      }
    }
  `);

  const variables = {
    data: variantsData,
  };

  return gqlClient.request(queryDoc, variables);
};

export type IFetchProductGroupsForProductsResult = {
  adminGraphQlId: string;
  variantOptions: {
    variantOptionName: string;
    id: string;
    priceGroups: Array<{
      variantOptionValue: string;
      id: string;
      priceSubGroups: Array<{
        url: string;
        title: string;
        description: string;
        id: string;
        designer: string;
        composition: string;
        materials: Array<any>;
      }>;
    }>;
  }[];
}[];

export const fetchPriceGroupsForProducts = async (data: string[]) => {
  const queryDoc = graphql(/* GraphQL */ `
    query Products($where: ProductWhereInput) {
      products(where: $where) {
        adminGraphQlId
        variantOptions {
          variantOptionName
          id
          priceGroups {
            variantOptionValue
            id
            priceSubGroups {
              url
              title
              description
              id
              designer
              composition
              category {
                materials {
                  id
                  previewImageUrl
                  title
                }
              }
              materials {
                id
                previewImageUrl
                title
              }
            }
          }
        }
      }
    }
  `);
  const variables = {
    where: {
      adminGraphQlId: {
        in: data,
      },
    },
  };

  return gqlClient.request(queryDoc, variables);
};
export const getAllPriceGroupCount = async () => {
  const queryDoc = graphql(/* GraphQL */ `
    query priceGroupCount($where: PriceGroupWhereInput) {
      aggregatePriceGroup(where: $where) {
        _count {
          _all
        }
      }
    }
  `);
  const variables = {
    where: {
      variantOptionId: {
        not: {
          equals: null,
        },
      },
    },
  };
  return gqlClient.request(queryDoc, variables);
};
export const updatePriceSubgroupById = async (data: {
  id: string;
  title?: string;
  composition?: string;
  url?: string;
  materials?: string[];
  designer?: string;
  description: string;
}) => {
  const queryDoc = graphql(/* GraphQL */ `
    mutation UpdateOnePriceSubGroup(
      $data: PriceSubGroupUpdateWithShopifyInput!
      $where: PriceSubGroupWhereUniqueIdInput!
    ) {
      updateOnePriceSubGroup(data: $data, where: $where) {
        id
        materialIds
        designer
        description
        title
        url
      }
    }
  `);

  const variables: any = {
    data: {},
    where: {
      id: data?.id,
    },
  };
  if (data.title) {
    variables.data.title = { set: data.title };
  }
  if (data.composition) {
    variables.data.composition = data.composition;
  }
  if (data.description) {
    variables.data.description = { set: data.description };
  }
  if (data.designer) {
    variables.data.designer = data.designer;
  }
  if (data.url) {
    variables.data.url = data.url;
  }
  if (data.materials) {
    variables.data.materialIds = data.materials;
  }
  return gqlClient.request(queryDoc, variables);
};
export type SubgroupDataProps = {
  title: string;
  composition: string;
  url: string;
  materialIds: string[];
  designer: string;
  description: string;
  categoryId: string;
};
export type ICreatePriceGroupForVariantMutationVars = {
  productGqlId: string;
  variantOptionName: string;
  variantOptionValue: string;
  productData?: any;
  subgroupData: SubgroupDataProps[];
};

export const createPriceGroupForVariantMutation = async ({
  productData,
  subgroupData,
  variantOptionName,
  variantOptionValue,
  productGqlId,
}: ICreatePriceGroupForVariantMutationVars): Promise<{
  __typename?: 'PriceGroup';
  id: string;
}> => {
  const existingVariantOptionNameObj = productData?.variantOptions.find(
    (variant: { variantOptionName: string }) =>
      variant.variantOptionName === variantOptionName,
  );
  let variantOptionNameObjId = existingVariantOptionNameObj?.id;
  const isVariantOptionNameCreated = !!existingVariantOptionNameObj;
  let updatingPriceGroupId = '';
  let updatingPriceGroupSubgroups: string[] = [];
  let isVariantOptionValueCreated = false;
  if (existingVariantOptionNameObj) {
    const existingVariantOptionValueObj =
      existingVariantOptionNameObj.priceGroups.find(
        (_pg: { variantOptionValue: string }) =>
          _pg.variantOptionValue === variantOptionValue,
      );
    if (existingVariantOptionValueObj) {
      isVariantOptionValueCreated = true;
      updatingPriceGroupId = existingVariantOptionValueObj.id;
      updatingPriceGroupSubgroups =
        existingVariantOptionValueObj.priceSubGroups.map(
          (subgroup: { id: any }) => subgroup.id,
        );
    }
  }

  if (!variantOptionNameObjId) {
    const res = await gqlClient.request(
      graphql(/* GraphQL */ `
        mutation CreateOneVariantOption(
          $data: CreateVariantOptionWithShopifyDefinitionInput!
        ) {
          createOneVariantOption(data: $data) {
            id
          }
        }
      `),
      {
        data: {
          priceGroupIds: [],
          productGqlId,
          variantOptionName,
        },
      },
    );
    variantOptionNameObjId = res.createOneVariantOption.id;
  }

  const promiseArray = subgroupData.map(
    (currSubgroupData: SubgroupDataProps) => {
      const queryDoc = graphql(/* GraphQL */ `
        mutation CreateOnePriceSubGroup(
          $data: PriceSubGroupCreateWithShopifyInput!
        ) {
          createOnePriceSubGroup(data: $data) {
            id
            title
          }
        }
      `);
      const variables = {
        data: currSubgroupData,
      };

      return gqlClient.request(queryDoc, variables);
    },
  );
  const res = await Promise.all(promiseArray);
  const subGroupIds = res.map(item => item.createOnePriceSubGroup.id as string);
  let currPriceGroupId: string = '';
  let priceGroupResponse:
    | UpdateOnePriceGroupMutation
    | CreateOnePriceGroupMutation
    | undefined;
  if (isVariantOptionNameCreated && isVariantOptionValueCreated) {
    // TODO: Update Price Group API call
    priceGroupResponse = await gqlClient.request(
      UPDATE_ONE_PRICE_GROUP_MUTATION,
      {
        data: {
          priceSubGroupIds: [...updatingPriceGroupSubgroups, ...subGroupIds],
          variantOptionValue,
        },
        where: {
          id: updatingPriceGroupId,
        },
      },
    );
    currPriceGroupId = priceGroupResponse.updateOnePriceGroup.id;
    await gqlClient.request(
      graphql(/* GraphQL */
      `
        mutation UpdateOneVariantOption(
          $data: UpdateOneVariantOptionInput!
          $where: VariantOptionWhereUniqueIdInput!
        ) {
          updateOneVariantOption(data: $data, where: $where) {
            id
          }
        }
      `),
      {
        data: {
          priceGroupIds: [
            ...new Set(
              (
                existingVariantOptionNameObj?.priceGroups.map(
                  (pg: { id: any }) => pg.id,
                ) ?? []
              ).concat(currPriceGroupId),
            ).values(),
          ] as string[],
        },
        where: {
          id: variantOptionNameObjId,
        },
      },
    );

    return priceGroupResponse.updateOnePriceGroup;
  }
  const queryDoc = graphql(/* GraphQL */ `
    mutation CreateOnePriceGroup(
      $data: CreateOnePriceGroupWithShopifyDefinitionInput!
    ) {
      createOnePriceGroup(data: $data) {
        id
      }
    }
  `);
  const variables = {
    data: {
      priceSubGroupIds: subGroupIds,
      variantOptionValue,
    },
  };

  priceGroupResponse = await gqlClient.request(queryDoc, variables);
  currPriceGroupId = priceGroupResponse.createOnePriceGroup.id;
  await gqlClient.request(
    graphql(/* GraphQL */
    `
      mutation UpdateOneVariantOption(
        $data: UpdateOneVariantOptionInput!
        $where: VariantOptionWhereUniqueIdInput!
      ) {
        updateOneVariantOption(data: $data, where: $where) {
          id
        }
      }
    `),
    {
      data: {
        priceGroupIds: [
          ...new Set(
            (
              existingVariantOptionNameObj?.priceGroups.map(
                (pg: { id: any }) => pg.id,
              ) ?? []
            ).concat(currPriceGroupId),
          ).values(),
        ] as string[],
      },
      where: {
        id: variantOptionNameObjId,
      },
    },
  );

  return priceGroupResponse.createOnePriceGroup;
};
